* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}
.App {
  transition: all 0.5s ease;
}
.library-active {
  margin-left: 30%;
}

h1 {
  font-size: 1rem;
}
h2 {
  color: rgb(51, 51, 51);
}
h3,
h4 {
  color: rgb(100, 100, 100);
  font-weight: 400;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";
